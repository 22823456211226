* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background-color: #ffffff;
    font-family: 'Poppins', sans-serif;
}

.container {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    padding: 0 40px;
}

ul {
    list-style: none;
}

a {
    text-decoration: none;
}

button {
    cursor: pointer;
}

.tool-selection {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.tool-selection h1 {
    color: #4a4a4a;
    font-size: 2.5rem;
    text-align: center;
}

.tools-selection-button {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.go-back-to-main,
.tools-buttons {
    font-size: 14px;
    font-weight: 700;
    line-height: 1.5;
    text-transform: uppercase;
    padding: 10px 20px;
    color: #ffffff;
    background-color: #0051aa;
    transition: all .3s;
    cursor: pointer;
    text-align: left;
    border-radius: 4px;
    border: 1px solid #0051aa;
    display: flex;
    align-items: center;
    gap: 100px;
}

.go-back-to-main:focus,
.go-back-to-main:hover,
.tools-buttons:focus,
.tools-buttons:hover {
    color: #fff;
    background-color: #003f84;
    border-color: #003977;
}

.tools-buttons:not(:disabled):not(.disabled):active:focus,
.show>.tools-buttons.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(38, 107, 183, .5);
}

.go-back-to-main {
    margin: 40px auto;
}