body:has(.calender-share) {
    background-color: rgba(248, 250, 252, 1);
}

.calender-share .container {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 40px;
}

.calendeer-section {
    display: flex;
    padding: 40px 0;
}

.calender-form {
    display: flex;
    flex-direction: column;
    gap: 24px;
    border-radius: 12px;
    padding: 40px;
    background-color: #ffffff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);
}

.calender-form h2 {
    font-size: 18px;
    line-height: 28px;
    font-weight: 700;
}

.calender-form div {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.calender-form div label {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    color: rgba(0, 0, 0, 1);

}

.calender-form input[type="date"],
.calender-form input[type="datetime-local"],
.calender-form input[type="text"],
.calender-form textarea,
.calender-form select,
.ql-editor {
    padding: 8px 16px;
    width: 100%;
    border-radius: 6px;
    outline: 2px solid transparent;
    border: 1px solid #000000;
    transition: border .3s;
    font-size: 16px;
    line-height: 24px;
}

.ql-tooltip.ql-hidden {
    display: none;
}

.calender-form input[type="text"]:focus {
    outline: 2px solid;
    border: none;
    outline-offset: 2px;
}

.calender-form div .labelcheckmark {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.calender-form div .labelcheckmark div {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.calender-form [type=checkbox],
.calender-form [type=radio] {
    border-radius: 4px;
    height: 16px;
    width: 16px;
    cursor: pointer;
}

.calender-form [type=checkbox]:checked,
.calender-form [type=radio]:checked {
    background-image: url('./images/tick.svg');
    border-color: transparent;
    background-color: currentColor;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    accent-color: #000000;
}

.calender-form button {
    background-color: #000000;
    color: #fff;
    padding: 12px 0;
    border: none;
    border-radius: 5px;
    line-height: 28px;
    font-size: 20px;
    font-weight: 600;
}

.calender-form button:hover {
    background-color: rgb(20, 31, 45);
}

.calender-links {
    margin-top: 50px;
    border-radius: 12px;
    padding: 40px;
    background-color: #ffffff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);
}

.calender-links .calender-links-wrap {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.calender-links ul,
.calender-links .calender-links-wrap div {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.calender-links h4 {
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
}

.calender-links ul,
.calender-links p {
    font-size: 16px;
}