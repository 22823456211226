body:has(.fancy-text) {
  font-family: 'Poppins', sans-serif;
  background-color: #f7f8fa;
  color: #333;
}

.fancy-text .container {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}

.main_wrapper {
  position: relative;
  padding: 50px 15px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.main_wrapper h2 {
  text-align: center;
  font-size: 2.5rem;
  color: #4a4a4a;
  margin-bottom: 20px;
}

.main-wrapper input {
  display: block;
  margin: 0 auto 30px;
  padding: 15px 20px;
  width: 80%;
  max-width: 600px;
  font-size: 18px;
  border: 1px solid #ddd;
  border-radius: 5px;
  outline: none;
  transition: all 0.3s ease;
}

.main-wrapper input:focus {
  border-color: #6c63ff;
  box-shadow: 0 0 10px rgba(108, 99, 255, 0.1);
}

.tranformed_text {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background-color: #f1f1f1;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: all 0.2s ease;
}

.tranformed_text:hover {
  background-color: #eaeaea;
}

.tranformed_text p {
  text-align: center;
  font-size: 24px;
  color: #444;
  margin-right: 20px;
}

.tranformed_text .buttons {
  display: flex;
  align-items: center;
  gap: 20px;
}

.tranformed_text button {
  padding: 8px 16px;
  background-color: #6c63ff;
  border: none;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.tranformed_text button:hover {
  background-color: #5a54d9;
}

/* Modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 40px 40px 0;
  border-radius: 5px;
  width: 80%;
  max-height: 80vh;
  overflow-y: auto;
  text-align: center;
  position: relative;
  scrollbar-width: thin;
  scrollbar-color: rgba(108, 99, 255, 0.5) transparent;
}

.modal-content::-webkit-scrollbar {
  width: 10px;
}

.modal-content::-webkit-scrollbar-track {
  background: rgba(247, 248, 250, 0.5);
  border-radius: 10px;
}

.modal-content::-webkit-scrollbar-thumb {
  background-color: rgba(108, 99, 255, 0.8);
  border-radius: 10px;
  transition: background-color 0.3s ease;
}

.modal-content::-webkit-scrollbar-thumb:hover {
  background-color: rgba(108, 99, 255, 1);
}

.close-icon {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 30px;
  color: #5a54d9;
  cursor: pointer;
}

.item-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  padding: 40px 0;
}

.item-box {
  background: #f0f0f0;
  padding: 10px;
  border-radius: 3px;
}

.item-box button {
  background-color: unset;
  border: unset;
  cursor: pointer;
}

.text_decor {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.text_decor button {
  padding: 8px 16px;
  background-color: #6c63ff;
  border: none;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.text_decor button:hover {
  background-color: #5a54d9;
}

/* Responsive Styles */
@media (max-width: 768px) and (min-width: 320px) {
  h1 {
    font-size: 32px;
  }

  .main-wrapper input {
    width: 90%;
    font-size: 16px;
  }

  .tranformed_text {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }

  .tranformed_text p {
    font-size: 20px;
  }

  .tranformed_text button {
    font-size: 12px;
  }

  .modal-content {
    width: 90%;
  }

  .item-list {
    align-items: flex-start;
  }

}