body:has(.policies-terms) {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.policies-terms .container {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 0 40px;
}

/* Main Page */
.terms-feed-main {
  background-color: #fafafa;
  text-align: center;
  padding: 32px 0;
}

.terms-feed-main h1 {
  font-size: 32px;
  font-weight: 700;
}

.cards-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  padding: 40px 0;
}

.card-main-top,
.card-main-bottom {
  display: flex;
  gap: 16px;
}

.card {
  padding: 30px 20px;
  color: #363636;
  border-radius: 8px;
  position: relative;
  background-color: #fafafa;
  transition: box-shadow 0.3s ease;
  border: 1px solid rgba(54, 54, 54, .125);
  box-shadow: 0 2px 4px rgba(54, 54, 54, .075);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;
  cursor: pointer;
  justify-content: space-between;
  text-align: left;
}

.card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card h3 {
  font-size: 36px;
  font-weight: 700;
  color: #363636;
}

.card p {
  font-size: 16px;
  color: #666666;
}

.card:hover {
  box-shadow: 0 10px 10px rgba(54, 54, 54, .075);
}

#privacy-policy {
  width: 50%;
}

.column-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.column-container h3 {
  font-size: 20px;
}

.start-up {
  display: flex;
  align-items: center;
  gap: 5px;
}

svg.svg-inline--fa.fa-angle-right {
  font-size: 14px;
  color: #666666;
}

/* #cookies-policy, */

#cookie-consent,
#eula,
#disclaimer,
#return-and-refund-policy {
  width: 33%;
}

#cookie-consent h3,
#eula h3,
#disclaimer h3,
#return-and-refund-policy h3 {
  font-size: 20px;
  font-weight: 700;
}

svg.svg-inline--fa.fa-shield-halved {
  font-size: 60px;
  color: #ffab1e;
}

#privacy-policy:hover {
  border: 1px solid #ffab1e;
  background: #fbf1dd;
}

svg.svg-inline--fa.fa-check-double {
  font-size: 30px;
  color: #65b167;
}

#terms-and-conditions:hover {
  border: 1px solid #65b167;
  background: #e2f2e3;
}

svg.svg-inline--fa.fa-lock {
  font-size: 30px;
  color: #9074c9;
}

#cookie-consent:hover {
  border: 1px solid #9074c9;
  background: #e3ddee;
}

svg.svg-inline--fa.fa-cookie-bite {
  font-size: 30px;
  color: #35afa3;
  transform: rotate(250deg)
}

#cookies-policy:hover {
  border: 1px solid #35afa3;
  background: #e3f2f1;
}

#eula svg.svg-inline--fa.fa-gear {
  font-size: 30px;
  color: #3d8dc3;
}

#eula:hover {
  border: 1px solid #3d8dc3;
  background: #e2eef6;
}

svg.svg-inline--fa.fa-circle-exclamation {
  font-size: 30px;
  color: #eb6067;
}

#disclaimer:hover {
  border: 1px solid #eb6067;
  background: #fae6e7;
}

svg.svg-inline--fa.fa-rotate {
  font-size: 30px;
  color: #c9528d;
}

#return-and-refund-policy:hover {
  border: 1px solid #c9528d;
  background: #f2e3eb;
}

/* Form */

.terms-generator {
  padding: 50px 0;
}

.form-inner {
  display: flex;
  align-items: flex-start;
  gap: 50px;
}

.stepper {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  position: relative;
}

.stepper::before {
  content: '';
  position: absolute;
  width: 4px;
  background-color: #e0e0e0;
  top: 20px;
  bottom: 20px;
  left: 15px;
  z-index: 0;
}

.step {
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ddd;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  z-index: 1;
  font-weight: bold;
}

.step.active {
  background-color: #0c5dae;
  box-shadow: 0 0 1px 1px #0c5dae;
  border: 3px solid #fff;
}

.step-number {
  font-weight: bold;
}

.step-outer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.step-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 635px;
}

.form-inner h2 {
  font-size: 28px;
  font-weight: 700;
  line-height: 1.2;
  text-transform: capitalize;
}

.step-content h3 {
  font-size: 20px;
  font-weight: 700;
}

.step-content p {
  color: #707070;
  font-size: 16px;
}

.options {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  cursor: pointer;
}

.option-card {
  border: 2px solid #dddddd;
  border-radius: 4px;
  padding: 25px 20px;
  width: 635px;
  height: 115px;
  transition: all .3s;
}

.option-card.active {
  background: #ffffff;
  border-color: #0051aa;
  box-shadow: 0 0 6px 0 rgba(46, 127, 209, .6);
}

.option-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.option-card h4 {
  font-size: 18px;
  font-weight: 700;
}

.option-card.active h4 {
  color: #0051aa;
}

.option-card p {
  font-size: 14px;
  color: #666;
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.next-button {
  font-size: 14px;
  font-weight: 700;
  line-height: 1.5;
  text-transform: uppercase;
  padding: 10px 20px;
  color: #ffffff;
  background-color: #0051aa;
  transition: all .3s;
  cursor: pointer;
  text-align: left;
  border-radius: 4px;
  border: 1px solid #0051aa;
  display: flex;
  align-items: center;
  gap: 100px;
}

svg.svg-inline--fa.fa-arrow-right {
  font-size: 14px;
  font-weight: 400;
  color: #a1c0df;
}

.next-button:focus,
.next-button:hover {
  color: #fff;
  background-color: #003f84;
  border-color: #003977;
}

.next-button:not(:disabled):not(.disabled):active:focus,
.show>.next-button.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(38, 107, 183, .5);
}

.prev-button {
  font-size: 14px;
  font-weight: 700;
  line-height: 1.5;
  text-transform: uppercase;
  padding: 10px;
  color: #848484;
  background-color: #ffffff;
  border-color: transparent;
  transition: all .3s;
  cursor: pointer;
  text-align: left;
  border-radius: 4px;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  gap: 10px;
}

svg.svg-inline--fa.fa-arrow-left {
  font-size: 14px;
  font-weight: 400;
  color: #848484;
}

.prev-button:hover {
  color: #555;
}

.prev-button:not(:disabled):not(.disabled):active,
.show>.prev-button.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
  box-shadow: 0 0 0 .2rem rgba(216, 217, 219, .5);
}

.next-button:disabled {
  opacity: .65;
  cursor: not-allowed;
}

.prev-button:disabled {
  display: none;
}

.input-field {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.input-field label {
  font-size: 20px;
  font-weight: 700;
}

.input-field input,
.input-field select {
  width: 100%;
  height: 50px;
  font-size: 18px;
  padding: 0 15px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  border-radius: 4px;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, .1);
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.input-field input:focus,
.input-field select:focus {
  color: #495057;
  background-color: #ffffff;
  border-color: #2b90ff;
  outline: 0;
  box-shadow: none;
}

.radio-input-field {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.radio-input-field p {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.5;
  color: #212529;
}

.radio-input-field .p-field {
  color: #707070;
  font-size: 16px;
  font-weight: 400;
}

.radio-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
}

.radio-input label {
  color: #555555;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
  align-items: center;
  gap: 15px;
}

.radio-input-field {
  margin-bottom: 16px;
}

.custom-radio {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  font-size: 16px;
}

.custom-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.radio-checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border: 1px solid #adb5bd;
  border-radius: 50%;
}

.custom-radio input:checked~.radio-checkmark {
  background-color: #0051aa;
}

.custom-radio input:checked~.radio-checkmark::after {
  content: "";
  position: absolute;
  left: 6px;
  top: 4px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

input[type="checkbox"]+.radio-checkmark {
  border-radius: 4px;
}

.business-details {
  width: 635px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.business-details .input-field {
  border: 1px solid #cecece;
  border-radius: 4px;
  background-color: #fafafa;
  position: relative;
  gap: unset;
}

.business-details .input-field.border-style:before {
  border-color: #eff2f5 transparent;
  z-index: 0;
  top: -9px;
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 0 9px 9px;
  display: block;
  width: 0;
  left: 23px;
}

.business-details .input-field label,
.business-details .input-field h3 {
  position: relative;
  width: 100%;
  font-size: 16px;
  color: #666;
  background-color: #eff2f5;
  font-weight: 700;
  top: 0;
  margin: 0;
  padding: 10px 20px;
  border-bottom: 1px solid #cecece;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.business-details .input-field input {
  width: calc(100% - 40px);
  margin: 20px 20px 15px;
  box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, .1);
}

.business-details .input-field p {
  font-size: 14px;
  padding: 8px 20px 20px;
  color: #707070;
  font-weight: 400;
  line-height: 1.5;
}

.radio-input .business-radio {
  color: #707070;
  font-size: 16px;
  font-weight: 400;
}

.mid-check {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.choose {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.optional {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.choose p {
  color: #212529;
}

.choose ul.list-style {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 24px;
  list-style: none;
  margin: 25px 0;
}

.choose ul.list-style li {
  width: 45%;
  display: flex;
  gap: 24px;
}

.choose ul.list-style li img {
  width: 50px;
  height: 50px;
}

.choose ul.list-style li p {
  display: flex;
  flex-direction: column;
}

.example-list {
  margin-left: 40px;
}

.policy-options {
  border: 1px solid #ccc;
  background: #eee;
  border-radius: 10px;
  padding: 25px 20px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.policy-options label,
.additional-fields label {
  color: #555555;
  font-size: 18px;
}

.additional-fields {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.business-details.analytic .input-field p {
  font-size: 16px;
}

.business-details.analytic .input-field label {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #555555;
  background-color: unset;
  border-bottom: unset;
  padding: 0 0 0 30px;
  width: 45%;
}

.business-details.analytic .input-field input {
  width: unset;
}

.analytic-inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 15px;
  padding: 0 20px 20px;
}

.business-details.analytic button {
  width: calc(100% - 40px);
  font-size: 14px;
  color: #848484;
  font-weight: 700;
  text-transform: uppercase;
  border: 1px dashed #848484;
  border-radius: 4px;
  margin: 0 20px 20px;
  transition: all .3s;
  padding: 8px 12px;
  background-color: transparent;
  cursor: pointer;
}

.business-details.analytic button:hover {
  color: #363636;
  border-color: #363636;
}

.business-details.add-own {
  width: 610px;
  margin: 0 0 0 auto;
  position: relative;
}

.business-details.add-own .input-field.border-style:before {
  display: none;
}

.business-details.add-own .input-field {
  padding: 50px 25px 20px;
  border-style: dashed;
  gap: 20px;
}

.business-details.analytic .business-details.add-own .input-field label {
  position: relative;
  width: 100%;
  font-size: 15px;
  color: #666;
  font-weight: 700;
  padding: 0;
}

.badge {
  font-size: 10px;
  line-height: 1;
  text-align: center;
  color: #b6b6b6;
  border: 1px solid #b6b6b6;
  padding: 4px 8px 3px;
  border-radius: 4px;
  margin: 0 10px 0 0;
}

.business-details.analytic .business-details.add-own .input-field input {
  width: 100%;
  margin: 12px 0 0;
}

svg.svg-inline--fa.fa-trash-can {
  position: absolute;
  right: 25px;
  top: 25px;
  color: #d02323;
  position: absolute;
  font-size: 14px;
}

.generate {
  font-size: 18px;
  font-weight: 700;
  background: #32ae0c;
  padding: 10px 20px;
  text-transform: capitalize;
  transition: all .3s;
  color: #ffffff;
  border-radius: 4px;
  border: 1px solid #32ae0c;
  line-height: 1.5;
  cursor: pointer;
}

.generate:focus {
  box-shadow: 0 0 0 .2rem rgba(116, 195, 87, .5);
}

.generate:hover {
  background: #48912d;
}

svg.svg-inline--fa.fa-download {
  margin-left: 10px;
}

.go-back {
  font-size: 18px;
  font-weight: 700;
  background: #707070;
  padding: 10px 20px;
  margin-right: 10px;
  text-transform: capitalize;
  transition: all .3s;
  color: #ffffff;
  border: 1px solid #707070;
  border-radius: 4px;
  line-height: 1.5;
  cursor: pointer;
}

.go-back:focus {
  box-shadow: 0 0 0 .2rem hsla(0, 0%, 49%, .5);
}

.go-back:hover {
  background: #595959;
}

.download-page {
  background-color: #fafafa;
}

.download-page-main {
  padding: 100px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.copy-text-main {
  width: 50%;
}

textarea {
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

textarea:focus {
  color: #495057;
  background-color: #fff;
  outline: 0;
  box-shadow: none;
}

.copy-button {
  color: #666666;
  border: 1px solid #666666;
  padding: 8px 12px;
  font-size: 16px;
  border-radius: 4px;
  background-color: transparent;
  cursor: pointer;
}

svg.svg-inline--fa.fa-copy {
  margin-right: 8px;
}

.copy-text {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 16px;
}

.copied {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #d4edda;
  color: #155724;
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 12px;
}

.preview-format {
  width: 50%;
  background-color: #ffffff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, .06);
  border: 1px solid rgba(54, 54, 54, .125);
}

.preview-main {
  display: flex;
  flex-direction: column;
}

.preview-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(54, 54, 54, .03);
  border-bottom: 1px solid rgba(54, 54, 54, .125);
  padding: 15px;
}

.preview-icons {
  display: flex;
  align-items: center;
  gap: 5px;
}

.red-circle,
.yellow-circle,
.green-circle {
  color: #fd9d95;
  font-size: 10px;
}

.yellow-circle {
  color: #fdd667;
}

.green-circle {
  color: #70de84;
}

.preview-head p {
  color: #666666;
}

.view {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 15px 30px 40px;
  color: #555555;
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: 800px
}

.view p a {
  color: #555555;
  text-decoration: none;
}

.view p a:hover {
  text-decoration: underline;
}

.view h2,
.view h3 {
  font-size: 20px;
}

.view h3 {
  color: #495972;
}

.view ul {
  padding-left: 40px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.text-area {
  height: 100px;
  width: 100%;
  font-size: 18px;
  padding: 0 15px;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 4px;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, .1);
}

.text-area:focus {
  background-color: #ffffff;
  border-color: #2b90ff;
  outline: 0;
  box-shadow: none;
}

@media (max-width: 991px) and (min-width: 375px) {
  .card-main-top {
    flex-direction: column;
    width: 100%;
  }

  .card-main-top {
    flex-wrap: wrap;
  }

  .card-main-bottom {
    flex-wrap: wrap;
    width: 100%;
    gap: 16px 0;
    justify-content: space-between;
  }

  .column-container {
    width: 100%;
    flex-direction: row;
  }

  #privacy-policy {
    width: 100%;
  }

  #cookies-policy,
  #eula,
  #disclaimer,
  #return-and-refund-policy {
    width: 49%;
  }

  .card {
    width: 50%;
  }
}

@media (max-width: 768px) and (min-width: 320px) {
  .column-container {
    flex-direction: column;
    width: 100%;
  }

  #cookies-policy,
  #eula,
  #disclaimer,
  #return-and-refund-policy {
    width: 100%;
  }

  .card {
    width: 100%;
  }
}